// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$feeds-client-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$feeds-client-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$feeds-client-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$feeds-client-theme: mat.m2-define-light-theme(
    (
        color: (
            primary: $feeds-client-primary,
            accent: $feeds-client-accent,
            warn: $feeds-client-warn
        ),
        typography: mat.m2-define-typography-config(),
        // density: 0,
    )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($feeds-client-theme);

/* You can add global styles to this file, and also import other style files */

$primary-color: var(--ui-static-color-blue);
$primary-color-dark: #1558a8;
$bf-white: #ffffff;

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    background-color: var(--ui-static-color-grey-98);
}

// https://stackoverflow.com/questions/75535246/angular-mat-form-field-not-working-properly-a-border-line-appears-inside-the-f
.mdc-notched-outline__notch {
    border-right: none !important;
}

:root {
    .ui-panel {
        --ui-margin: 0;

        .content {
            --ui-padding-small: 0;
        }

        .header {
            --ui-padding-small: 0;
        }
    }

    .ui-body {
        .scroll-wrapper {
            background-color: var(--ui-static-color-grey-98);
        }
    }

    .mat-mdc-standard-chip {
        --mdc-chip-container-shape-radius: 4px;
        --mdc-chip-container-height: 34px;
        --mdc-chip-elevated-container-color: #{$primary-color};
    }

    .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
        --mdc-chip-elevated-container-color: #{$primary-color};
    }

    .mat-mdc-radio-button
        .mdc-radio
        .mdc-radio__native-control:enabled:not(:checked)
        + .mdc-radio__background
        .mdc-radio__outer-circle {
        border-color: var(--ui-static-color-grey-86);
    }

    .mat-mdc-radio-button
        .mdc-radio
        .mdc-radio__native-control:enabled:checked
        + .mdc-radio__background
        .mdc-radio__outer-circle,
    .mat-mdc-radio-button
        .mdc-radio
        .mdc-radio__native-control:enabled
        + .mdc-radio__background
        .mdc-radio__inner-circle {
        border-color: var(--ui-static-color-blue);
    }

    .mat-step-icon-selected {
        --mat-stepper-header-edit-state-icon-background-color: #{$primary-color};
    }

    .mat-step-icon-state-done {
        --mat-stepper-header-done-state-icon-background-color: #{$primary-color};
    }

    .mat-step-icon {
        --mat-stepper-header-icon-foreground-color: #{$primary-color};
        --mat-stepper-header-icon-background-color: #{$bf-white};
        border: 1px solid #{$primary-color};
    }

    .mat-stepper-horizontal {
        --mat-stepper-container-color: rgb(245, 245, 245);
        width: 100%;
        height: 100%;
    }

    .mat-horizontal-stepper-header-container {
        width: 60%;

        .mat-step-header {
            .mat-step-icon {
                background-color: #{$bf-white};
                color: #{$primary-color};
                border: 1px solid #{$primary-color};
            }

            .mat-step-icon-selected,
            .mat-step-icon-state-done {
                background-color: #{$primary-color};
                color: #{$bf-white};
            }

            .mat-step-icon-state-done {
                background-color: #{$primary-color-dark};
            }
        }
    }

    .mat-horizontal-stepper-wrapper {
        height: 100%;
        align-items: center;
        background-color: var(--ui-static-color-grey-98);
    }

    .mat-horizontal-content-container {
        overflow-y: scroll;
        height: 100%;
        width: 100%;
        padding: 0;
    }

    .mat-horizontal-stepper-content {
        height: 100%;
    }

    .expression-input .mat-mdc-form-field-infix {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .mat-mdc-select-value-text,
    .mat-mdc-option {
        font-size: 14px;
    }

    .mat-pseudo-checkbox-checked {
        background-color: transparent !important;
    }

    .mat-mdc-form-field-flex {
        display: flex;
        align-items: center;
        max-height: 56px;
    }

    .mdc-evolution-chip-set__chips {
        flex-flow: nowrap;
        display: flex;
        align-items: center;
    }

    .ui-tabs .header {
        padding: 0;
        border-bottom: solid 1px var(--ui-color-border);
        border-right: none;
        border-left: none;
        border-top: none;
        background-color: var(--ui-static-color-grey-98);
    }

    .ui-tabs > .content {
        border: none;
        padding: 40px 20px 50px;
        background-color: var(--ui-static-color-grey-98);

        .preview-text {
            font-weight: 700;
        }
    }

    .button-group-template {
        --ui-height: 34px;

        .primary-selection {
            color: var(--ui-static-color-blue);
            background-color: var(--ui-static-color-white);
        }
    }

    .button-template {
        background-color: transparent;
        color: var(--ui-static-color-blue);

        .container {
            --padding: 0;

            &:active {
                background-color: transparent;
                color: var(--ui-static-color-midnight);
            }
        }
    }

    .button-publish {
        .button {
            height: 40px;
        }
    }

    .button-action-template {
        background-color: var(--ui-static-color-white);
        color: var(--ui-static-color-blue);

        .container {
            &:active {
                background-color: var(--ui-static-color-white);
                color: var(--ui-static-color-midnight);
            }
        }
    }

    .mat-mdc-select-panel {
        max-height: none;
    }

    .select-field .skeleton-row {
        width: 100%;
    }

    .skeleton-select-field .skeleton-row {
        width: 100%;
        height: 56px;
    }

    .feed-editor-source-url {

        .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mat-mdc-notch-piece,
        .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover
            .mat-mdc-notch-piece {
            border-color: #c4c4c4;
        }

        .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mat-mdc-notch-piece {
            border-width: 1px;
        }

        .mat-mdc-form-field-infix {
            display: flex;
            height: 20px;
            font-size: 12px;
        }
    }
}
